<template>
	<div class="d-flex generator">
		<v-card max-width="600" class="mx-auto px-1">
			<v-card-actions class="pa-4 pb-2 align-center">
				<v-icon>mdi-microsoft-excel</v-icon>
				<v-card-title class="pa-1">Generate Worksheet</v-card-title>
			</v-card-actions>
			<div class="px-4"><v-divider /></div>
			<v-card-text>
				<v-row class="ma-0 pa-0">
					<v-col cols="12">
						<staff-select v-model="payload.staff" :errors="formErrors" />
					</v-col>
					<v-col cols="12">
						<date-picker v-model="payload.fromDate" label="From Date" name="dateFrom"
							icon="mdi-sort-calendar-ascending" :errors="formErrors" />
					</v-col>
					<v-col cols="12">
						<date-picker v-model="payload.toDate" label="To Date" name="dateTo"
							icon="mdi-sort-calendar-descending" :errors="formErrors" />
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="pa-4 pt-2">
				<v-btn block height="40" color="primary" @click="generate" :loading="generating" :disabled="checkRequired">
					Generate
				</v-btn>
			</v-card-actions>
		</v-card>

		<v-card max-width="600" class="mx-auto px-1">
			<v-card-actions class="pa-4 pb-2 align-center">
				<v-icon>mdi-microsoft-excel</v-icon>
				<v-card-title class="pa-1">Generate Leave Report</v-card-title>
			</v-card-actions>
			<div class="px-4"><v-divider /></div>
			<v-card-text>
				<v-row class="ma-0 pa-0">
					<v-col cols="12">
						<staff-select v-model="payloadSheet.staff" :errors="formErrors" />
					</v-col>
					<v-col cols="12">
						<date-picker v-model="payloadSheet.fromDate" label="From Date" name="dateFrom"
							icon="mdi-sort-calendar-ascending" :errors="formErrors" />
					</v-col>
					<v-col cols="12">
						<date-picker v-model="payloadSheet.toDate" label="To Date" name="dateTo"
							icon="mdi-sort-calendar-descending" :errors="formErrors" />
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions class="pa-4 pt-2">
				<v-btn block height="40" color="primary" @click="generateLeave" :loading="generating" :disabled="checkRequiredSheet">
					Generate
				</v-btn>
			</v-card-actions>
		</v-card>

	</div>
</template>

<script>
// import XLSX from "xlsx"

import FetchStaffs from "@/mixins/FetchStaffs.js";
import Snack from "@/mixins/Snack.js";

export default {
	name: "Report",
	mixins: [FetchStaffs, Snack],
	data: () => ({
		generating: false,
		payload: {
			staff: null,
			fromDate: null,
			toDate: null
		},
		payloadSheet: {
			staff: null,
			fromDate: null,
			toDate: null
		},
		formErrors: {}
	}),
	computed: {
		checkRequired() {
			return !(this.payload.staff && this.payload.fromDate && this.payload.toDate);

		},
		checkRequiredSheet() {
			return !(this.payloadSheet.staff && this.payloadSheet.fromDate && this.payloadSheet.toDate);

		},
	},
	methods: {
		generate() {
			this.generating = true
			const url = this.$urls.worksheet
			this.$axios.getWithPayload(url, {
				staffId: this.payload.staff,
				dateFrom: this.payload.fromDate,
				dateTo: this.payload.toDate
			}).then(() => {
				let fullUrl = "%s%s?staffId=%s&dateFrom=%s&dateTo=%s"
				fullUrl = this.$util.format(
					fullUrl, this.$urls.backend,
					url, this.payload.staff,
					this.payload.fromDate, this.payload.toDate
				)
				const anchor = document.createElement("a")
				anchor.setAttribute("href", fullUrl)
				anchor.click()
			})
				.catch(err => {
					const status = err.response.status
					switch (status) {
						case 500:
							this.openSnack({ text: "Internal Server Error. Please try again later." })
							break
						default:
							this.openSnack({ text: "Something went wrong. Please try again later." })
							break
					}
				})
				.finally(() => {
					this.generating = false
				})
		},
		generateLeave() {
			this.generating = true
			const url = this.$urls.leavesheet
			this.$axios.getWithPayload(url, {
				staffId: this.payloadSheet.staff,
				dateFrom: this.payloadSheet.fromDate,
				dateTo: this.payloadSheet.toDate
			}).then(() => {
				let fullUrl = "%s%s?staffId=%s&dateFrom=%s&dateTo=%s"
				fullUrl = this.$util.format(
					fullUrl, this.$urls.backend,
					url, this.payloadSheet.staff,
					this.payloadSheet.fromDate, this.payloadSheet.toDate
				)
				const anchor = document.createElement("a")
				anchor.setAttribute("href", fullUrl)
				anchor.click()
			})
				.catch(err => {
					const status = err.response.status
					switch (status) {
						case 500:
							this.openSnack({ text: "Internal Server Error. Please try again later." })
							break
						default:
							this.openSnack({ text: "Something went wrong. Please try again later." })
							break
					}
				})
				.finally(() => {
					this.generating = false
				})
		}
	}
}
</script>

<style scoped lang="scss">
.generator{
	gap: 2ch;
}

</style>
