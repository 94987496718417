import {mapGetters, mapMutations} from "vuex";

const FetchStaffs = {
  computed: {
    ...mapGetters({
      staffs: "staff/list",
    })
  },
  async created() {
    await this.fetchStaffs()
  },
  methods: {
    ...mapMutations("staff", ["SET_LIST"]),
    async fetchStaffs() {
      if (!Object.entries(this.staffs).length) {
        const res = await this.$axios.get(this.$urls.staff.list)
        if (res) this.SET_LIST(res)
      }
    }
  }
}

export default FetchStaffs
